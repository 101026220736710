<template>
  <div>
    <form @submit.prevent="subscribe">
      <div
        v-if="subscribed"
        class="d-flex align-top gap-3 text-white text-left"
      >
        <v-icon color="success">mdi-thumb-up</v-icon>
        <div>
          <strong>{{
            $t(
              `mailchimp.${alreadySubscribed ? "already_subscribed" : "verify_subscription"}.title`,
            )
          }}</strong
          ><br />
          <span>{{
            $t(
              `mailchimp.${alreadySubscribed ? "already_subscribed" : "verify_subscription"}.text`,
            )
          }}</span>
        </div>
      </div>
      <div
        v-else
        :class="{
          'd-flex flex-grow-1 align-center gap-3': horizontal,
          'text-center flex-wrap': !horizontal || display.smAndDown.value,
        }"
      >
        <VTextField
          v-model="email"
          name="email"
          class="input"
          :class="{ 'w-100': !horizontal }"
          required
          hide-details="auto"
          density="compact"
          :placeholder="$t('mailchimp.email.placeholder')"
          :error-messages="error ? $t('default.email.error.invalid') : null"
          prepend-inner-icon="mdi mdi-email"
          @update:model-value="error = false"
        />
        <button
          name="subscribe"
          color="primary"
          class="button"
          :class="{ 'mt-4': !horizontal }"
          @click="subscribe"
        >
          {{ $t(`mailchimp.subscribe.button.label${!noCta ? "_cta" : ""}`) }}
        </button>
      </div>
    </form>
    <MyConfettiExplosion v-if="!noConfetti && subscribed" />
  </div>
</template>

<script setup>
import { useDisplay } from "vuetify";
const display = useDisplay();

defineProps({
  noConfetti: {
    type: Boolean,
    default: false,
  },
  horizontal: {
    type: Boolean,
    default: false,
  },
  noCta: {
    type: Boolean,
    default: false,
  },
});
const email = ref("");
const subscribed = ref(false);
const alreadySubscribed = ref(false);
const error = ref(false);

const { subscribeUser } = useMailchimp();
const emit = defineEmits(["subscribed", "pending"]);
const { cookie } = useMyPage();

async function subscribe() {
  if (email.value !== "") {
    cookie.set("subscribed", { expires: 365 });
    try {
      error.value = false;
      const { data, error: _error } = await subscribeUser(email.value);

      if (data?.status === "already_subscribed") {
        alreadySubscribed.value = true;
      }

      if (
        ["subscribed", "pending"].includes(data?.status) ||
        alreadySubscribed.value
      ) {
        subscribed.value = true;
        emit(data?.status === "pending" ? "pending" : "subscribed");
      } else {
        error.value = true;
      }
    } catch (error) {
      console.error(error);
      error.value = true;
    }
  } else {
    error.value = true;
  }
}
</script>

<style scoped>
.input {
  min-width: 250px;
}
</style>
